<template>
  <div
    class="bit-square flex-row justify-content-center"
    :class="isActive ? `bg-${colorName}-color` : 'bg-neutral-color-2'"
  >
    <p
      :class="
        isActive ? 'typography-neutral-color-1' : 'typography-neutral-color-10'
      "
    >
      {{ displayValue }}
    </p>
  </div>
</template>

<script>
export default {
  name: "BitSquare",
  props: {
    displayValue: {
      type: Number,
      default: 0
    },
    isActive: {
      type: Boolean,
      default: false
    },
    colorName: {
      type: String,
      default: "primary"
    }
  }
};
</script>

<style lang="scss">
.bit-square {
  border-radius: 0.375rem;
  height: 2rem;
  width: 2rem;
  line-height: 2rem;
  margin-bottom: 0.75rem;
  font-weight: $font-bold;
}
</style>
