var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"character",class:[
    _vm.colorName
      ? ("typography-" + _vm.colorName + "-color")
      : 'typography-neutral-color-3',
    _vm.textSize
  ],style:({
    marginTop: (_vm.marginY + "rem"),
    marginBottom: (_vm.marginY + "rem")
  })},[_vm._v(" "+_vm._s(_vm.displayValue)+" ")])}
var staticRenderFns = []

export { render, staticRenderFns }