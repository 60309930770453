<template>
  <div class="flex-row gap-4 align-items-center">
    <p
      :class="
        isActive ? 'typography-neutral-color-7' : 'typography-neutral-color-10'
      "
    >
      {{ titles[0] }}
    </p>
    <label class="switch">
      <p class="visually-hidden">{{ labelName }}</p>
      <input
        id="toggle-switch-input"
        type="checkbox"
        :checked="isActive"
        @click="onClick"
      />
      <span class="slider round bg-neutral-color-3"></span>
    </label>
    <p
      :class="
        isActive ? 'typography-neutral-color-10' : 'typography-neutral-color-7'
      "
    >
      {{ titles[1] }}
    </p>
  </div>
</template>

<script>
export default {
  name: "ToggleSwitch",
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    onClick: {
      type: Function,
      required: true
    },
    labelName: {
      type: String,
      required: true
    },
    titles: {
      type: Array,
      required: true
    }
  }
};
</script>

<style lang="scss">
.switch {
  position: relative;
  display: inline-block;
  width: 3.75rem;
  height: 2.125rem;

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &:before {
      position: absolute;
      content: "";
      height: 1.625rem;
      width: 1.625rem;
      left: 0.25rem;
      bottom: 0.25rem;
      background-color: $color-neutral-100;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }

  .round {
    border-radius: 2.125rem;

    &:before {
      border-radius: 50%;
    }
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider:before {
      -webkit-transform: translateX(1.625rem);
      -ms-transform: translateX(1.625rem);
      transform: translateX(1.625rem);
    }
  }
}
</style>
