<template>
  <div class="flex-row gap-4 justify-content-center flex-no-wrap margin-y-4">
    <div
      class="flex-row gap-4 align-items-flex-end flex-no-wrap"
      v-for="digit in digits"
      :key="digit.id"
    >
      <div class="flex-column gap-2">
        <BitSquare
          v-if="digit.bits === 8"
          :display-value="8"
          :color-name="digit.color"
          :is-active="digit.bitDigit[0] == 1"
        />
        <BitSquare
          v-if="digit.bits === 8 || digit.bits === 4"
          :display-value="4"
          :color-name="digit.color"
          :is-active="digit.bitDigit[1] == 1"
        />
        <BitSquare
          :display-value="2"
          :color-name="digit.color"
          :is-active="digit.bitDigit[2] == 1"
        />
        <BitSquare
          :display-value="1"
          :color-name="digit.color"
          :is-active="digit.bitDigit[3] == 1"
        />
        <Character
          :display-value="digit.decimalDigit"
          :color-name="digit.color"
          :text-size="'big'"
          :margin-y="2"
        />
        <Character :display-value="digit.bitDigit" :color-name="digit.color" />
      </div>
      <div v-if="digit.id === 2 || digit.id === 4">
        <Character :display-value="':'" :text-size="'big'" :margin-y="3.5" />
      </div>
    </div>
  </div>
</template>

<script>
import BitSquare from "./BitSquare";
import Character from "./Character";

export default {
  name: "Clock",
  data: function() {
    return {
      H: {
        id: 1,
        color: "primary",
        bits: 2,
        decimalDigit: "",
        bitDigit: ""
      },
      h: {
        id: 2,
        color: "primary",
        bits: 8,
        decimalDigit: "",
        bitDigit: ""
      },
      M: {
        id: 3,
        color: "success",
        bits: 4,
        decimalDigit: "",
        bitDigit: ""
      },
      m: {
        id: 4,
        color: "success",
        bits: 8,
        decimalDigit: "",
        bitDigit: ""
      },
      S: {
        id: 5,
        color: "info",
        bits: 4,
        decimalDigit: "",
        bitDigit: ""
      },
      s: {
        id: 6,
        color: "info",
        bits: 8,
        decimalDigit: "",
        bitDigit: ""
      }
    };
  },
  components: {
    BitSquare,
    Character
  },
  mounted() {
    setInterval(() => {
      this.handleDateValues(new Date(Date.now()));
    }, 500);
  },
  methods: {
    handleDateValues(dateObj) {
      const time = dateObj
        .toTimeString()
        .split(" ")[0]
        .split(":");

      this.setDecimals(time);
      this.setBits();
    },
    setDecimals(time) {
      this.H.decimalDigit = time[0][0];
      this.h.decimalDigit = time[0][1];
      this.M.decimalDigit = time[1][0];
      this.m.decimalDigit = time[1][1];
      this.S.decimalDigit = time[2][0];
      this.s.decimalDigit = time[2][1];
    },
    parseBits(decimalDigit) {
      return ("0000" + parseInt(decimalDigit).toString(2)).slice(-4);
    },
    setBits() {
      this.H.bitDigit = this.parseBits(this.H.decimalDigit);
      this.h.bitDigit = this.parseBits(this.h.decimalDigit);
      this.M.bitDigit = this.parseBits(this.M.decimalDigit);
      this.m.bitDigit = this.parseBits(this.m.decimalDigit);
      this.S.bitDigit = this.parseBits(this.S.decimalDigit);
      this.s.bitDigit = this.parseBits(this.s.decimalDigit);
    }
  },
  computed: {
    digits() {
      return [this.H, this.h, this.M, this.m, this.S, this.s];
    }
  }
};
</script>
