<template>
  <div
    class="character"
    :class="[
      colorName
        ? `typography-${colorName}-color`
        : 'typography-neutral-color-3',
      textSize
    ]"
    :style="{
      marginTop: `${marginY}rem`,
      marginBottom: `${marginY}rem`
    }"
  >
    {{ displayValue }}
  </div>
</template>

<script>
export default {
  name: "Character",
  props: {
    displayValue: {
      type: String,
      default: "X"
    },
    colorName: String,
    textSize: {
      type: String,
      default: "small"
    },
    marginY: {
      type: Number
    }
  }
};
</script>

<style lang="scss">
.character {
  width: 1rem;
  text-align: center;
  font-weight: $font-bold;
}

.big {
  font-size: 3rem;
}

.small {
  font-size: 1rem;
}
</style>
