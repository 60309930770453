<template>
  <div
    id="app"
    :class="{ dark: darkMode }"
    class="flex-column justify-content-space-between flex-no-wrap"
  >
    <header class="flex-column align-items-center gap-8 margin-6">
      <h1>
        Binary Clock
      </h1>
      <ToggleSwitch
        :isActive="darkMode"
        :onClick="toggleDarkMode"
        :labelName="'Light/Dark mode toggle'"
        :titles="['Light', 'Dark']"
      />
    </header>
    <main>
      <Clock />
    </main>
    <footer
      class="flex-column justify-content-space-between flex-grow padding-4"
    >
      <a
        class="flex-row justify-content-center gap-2"
        href="https://www.commithub.com/counting-in-binary/"
      >
        Learn how to count in binary
        <font-awesome-icon icon="external-link-alt" />
      </a>
      <div class="flex-row justify-content-center gap-4">
        <p>Made with 💖 in Detroit &copy; {{ currentYear }}</p>
      </div>
      <div class="flex-row justify-content-center gap-4">
        <a href="https://gitlab.com/commithub/binary-clock">
          Gitlab Repository
          <font-awesome-icon :icon="['fab', 'gitlab']" />
        </a>
        <a href="https://orimorningstar.com/">
          Ori Morningstar <font-awesome-icon icon="external-link-alt" />
        </a>
        <a href="https://legal.orimorningstar.com/">
          Terms and Conditions <font-awesome-icon icon="external-link-alt" />
        </a>
        <a href="https://legal.orimorningstar.com/privacy/">
          Privacy Policy <font-awesome-icon icon="external-link-alt" />
        </a>
        <a
          href="https://legal.orimorningstar.com/gnu-general-public-license-3/"
        >
          GNU General Public License Version 3
          <font-awesome-icon icon="external-link-alt" />
        </a>
        <a href="https://plausible.io/binary-clock.org">
          Analytics using Plausible
          <font-awesome-icon icon="external-link-alt" />
        </a>
      </div>
    </footer>
  </div>
</template>

<script>
import Clock from "./components/Clock";
import ToggleSwitch from "./components/ToggleSwitch";

export default {
  name: "App",
  components: {
    Clock,
    ToggleSwitch
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
      darkMode: false
    };
  },
  mounted() {
    if (localStorage.darkMode) this.darkMode = true;
  },
  methods: {
    toggleDarkMode() {
      if (this.darkMode) {
        this.darkMode = false;
        localStorage.removeItem("darkMode");
      } else {
        this.darkMode = true;
        localStorage.setItem("darkMode", true);
      }
    }
  }
};
</script>

<style lang="scss">
#app {
  height: 100vh;
}
</style>
